<template>
  <div class="bottom">
    <!-- 联系我们层 -->
    <div class="section-box" id="lxwm">
      <div class="section-layout">
        <!-- pc star -->
        <div class="section-sec">
          <h2 class="add-bold">联系我们</h2>
          <div class="contact-boxs">
            <!-- 商务合作 -->
            <div class="contact-block contact-hightlight">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="120"
                height="120"
                viewBox="0 0 120 120"
              >
                <g fill-rule="evenodd" fill="none">
                  <g class="cont4" fill-rule="nonzero" fill="#C8C9CC">
                    <path d="M10.94 60.265v-7.411h97.12v7.411z"></path>
                    <path
                      d="M59.503 76.311c-7.255-.009-13.134-5.91-13.146-13.195a13.096 13.096 0 012.26-7.405l5.858 4.565a5.802 5.802 0 001.34 7.275 5.743 5.743 0 007.37 0 5.802 5.802 0 001.34-7.275l5.859-4.56a13.25 13.25 0 01.726 13.588 13.14 13.14 0 01-11.613 7.013l.006-.006z"
                    ></path>
                  </g>
                  <path
                    class="cont1"
                    fill="#C8C9CC"
                    fill-rule="nonzero"
                    d="M72.156 26.194v-5.246c-.006-2.503-2.025-4.531-4.518-4.537h-16.27c-2.491.006-4.51 2.03-4.519 4.532v5.251h-7.385v-5.246C39.47 14.348 44.8 9 51.374 9h16.264c6.564.005 11.893 5.35 11.898 11.948v5.246h-7.385.005z"
                  ></path>
                  <path
                    stroke-width="8"
                    stroke="#C8C9CC"
                    d="M18.526 26.819c-4.708.006-8.523 3.92-8.526 8.746v60.513c0 4.828 3.817 8.735 8.526 8.74h81.948c4.703 0 8.52-3.912 8.526-8.74V35.565c0-4.828-3.817-8.74-8.526-8.746H18.526z"
                  ></path>
                  <path
                    stroke-width="8"
                    stroke="#C8C9CC"
                    d="M10 60v36.078c0 4.828 3.817 8.735 8.526 8.74h81.948c4.703 0 8.52-3.912 8.526-8.74V60"
                  ></path>
                  <path
                    class="cont2"
                    stroke="#FE8059"
                    stroke-width="8"
                    d="M18.526 26.819c-4.708.006-8.523 3.92-8.526 8.746v60.513c0 4.828 3.817 8.735 8.526 8.74h81.948c4.703 0 8.52-3.912 8.526-8.74V35.565c0-4.828-3.817-8.74-8.526-8.746H18.526z"
                  ></path>
                  <path
                    class="cont3"
                    stroke="#4C9AF2"
                    stroke-width="8"
                    d="M10 60v36.078c0 4.828 3.817 8.735 8.526 8.74h81.948c4.703 0 8.52-3.912 8.526-8.74V60"
                  ></path>
                </g>
              </svg>
              <div class="section-title">商务合作</div>
              <div class="section-email"><p>zeng@gdfanqie.com</p></div>
            </div>
            <!-- 办公地址 -->
            <div class="contact-block contact-hightlight">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="120"
                height="120"
                viewBox="0 0 120 120"
              >
                <g fill-rule="nozero" fill="none">
                  <path
                    stroke-width="9"
                    stroke="#C8C9CC"
                    d="M59.179 13C37.832 13 21 30.2 21 51c0 8 2.463 15.6 6.979 21.6 1.394 1.927 10.425 13.26 27.095 34 1.231 1.6 2.873 2.4 4.926 2.4s3.284-.8 4.926-2.4L91.2 73.8c3.265-4.65 7.8-14.4 7.8-22.4C97.358 30.2 80.526 13 59.179 13z"
                  ></path>
                  <path
                    class="contb1"
                    stroke-width="9"
                    stroke="#FE8059"
                    d="M59.179 13C37.832 13 21 30.2 21 51c0 8 2.463 15.6 6.979 21.6 1.394 1.927 10.425 13.26 27.095 34 1.231 1.6 2.873 2.4 4.926 2.4s3.284-.8 4.926-2.4L91.2 73.8c3.265-4.65 7.8-14.4 7.8-22.4C97.358 30.2 80.526 13 59.179 13z"
                  ></path>
                  <path
                    class="contb2"
                    stroke-width="9"
                    stroke="#4C9AF2"
                    d="M27.979 72.6c1.394 1.927 10.425 13.26 27.095 34 1.231 1.6 2.873 2.4 4.926 2.4s3.284-.8 4.926-2.4L91.2 73.8c3.265-4.65 7.8-14.4 7.8-22.4"
                  ></path>
                  <path
                    class="contb3"
                    fill="#C8C9CC"
                    d="M59.747 34c9.506 0 16.748 7.653 16.748 17.106 0 9.453-7.695 17.106-16.748 17.106C50.695 68.212 43 60.56 43 51.106 43 41.653 50.242 34 59.747 34zm-.147 9c-4.979 0-8.6 3.601-8.6 8.553 0 4.502 4.074 8.553 8.6 8.553s8.6-3.601 8.6-8.553c0-4.502-3.621-8.553-8.6-8.553z"
                  ></path>
                </g>
              </svg>
              <div class="section-title">办公地址</div>
              <div class="section-email">
                <p>广东省中山市南区永安一路9号悦创中心B区904卡</p>
              </div>
            </div>
          </div>
        </div>
        <!-- pc end -->
        <!-- 移动 star  -->
        <div class="mobile-section" id="lxwm">
          <h2 class="add-bold">联系我们</h2>
          <div class="mobile-ways">
            <div class="ways-block">
              <img src="../assets/image/icn-hza.png" />
              <div class="ways-block-detil">
                <div class="add-bold">商务合作</div>
                <div>zeng@gdfanqie.com</div>
              </div>
              <div style="flex: 1 1 auto"></div>
              <img src="../assets/image/icn-rightc.png" />
            </div>
          </div>
        </div>
        <!-- 移动 end -->
      </div>
    </div>
    <!-- 底部 -->
    <div class="bottom">
      <div class="bottom-layout">
        <div class="bottom-container">
          © 2021 Tomato Technology | 广东番茄科技有限公司 |
          <a href="https://beian.miit.gov.cn" target="_blank"
            >粤ICP备2021062901号-1</a
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "../assets/less/bottom.less";
export default {};
</script>

<style lang="less" scoped></style>
